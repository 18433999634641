<template>
  <Popup
    :width="width"
    :margin="margin"
    :radiusType="radiusType"
    :showDarkClose="showDarkClose"
    :closePopupEvent="closePopup"
  >
    <div class="content_detail" slot="component">
      <!-- <div v-if="placeholder"></div> -->
      <div style="position: relative">
        <div class="content_detail_container">
          <div class="content_detail_container_card_image" :style="[{ 'background-image': `url(${getPoster()})` }]">
            <div class="content_detail_container_card_image_content_tag_container">
              <div :class="[localDisplayLang === 'ara' ? 'tag_right' : 'tag_left']" v-if="isObjectTag(content)">
                <p>{{ $t(`${content.objecttag[0]}`) }}</p>
              </div>
            </div>
            <ul class="content_detail_container_card_image_info">
              <!-- <li class="content_detail_container_card_image_info_content_tag" v-if="isObjectTag(content)">
                <p>{{ $t(`${content.objecttag[0]}`) }}</p>
              </li> -->
              <li class="content_detail_container_card_image_info_title">{{ content.title }}</li>
              <li>
                <ul class="content_detail_container_card_image_info_tags">
                  <li>
                    {{
                      content.category === "TVSHOW"
                        ? $t("TVSHOW")
                        : content.category === "MOVIE"
                        ? $t("MOVIE")
                        : content.category
                    }}
                  </li>
                  <li>{{ content.genre }}</li>
                  <li>{{ content.contentlanguage[0] ? $t(`${content.contentlanguage[0]}`) : "" }}</li>
                  <li>{{ content.productionyear }}</li>
                  <li>{{ content.pgrating }}</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div class="content_detail_description">
          <div class="content_detail_description_duration">
            <div v-if="content.category !== 'TVSHOW'" class="content_detail_description_duration_movie" dir>
              <div class="flex" v-if="localDisplayLang === 'ara'">
                <p dir="ltr">{{ calcTimeFormat(content.duration) }}</p>
                <img
                  src="@/assets/icons/Duration.svg"
                  alt="items-count"
                  :class="[localDisplayLang === 'ara' ? 'margin-left' : 'margin-right']"
                />
              </div>
              <div class="flex" v-else>
                <img
                  src="@/assets/icons/Duration.svg"
                  alt="items-count"
                  :class="[localDisplayLang === 'ara' ? 'margin-left' : 'margin-right']"
                />
                <p>{{ calcTimeFormat(content.duration) }}</p>
              </div>
            </div>
            <div
              v-if="content.category === 'TVSHOW' && content.seasoncount !== 0"
              class="content_detail_description_duration_tvshow"
            >
              <img
                src="@/assets/icons/season-light.svg"
                alt
                :class="[localDisplayLang === 'ara' ? 'margin-left' : 'margin-right']"
              />
              <p>{{ content.seasoncount }} {{ content.seasoncount === 1 ? $t("Season") : $t("Seasons") }}</p>
            </div>
          </div>
          <div class="content_detail_description_grid" v-if="!readMore">
            <div>
              <p class="content_detail_description_grid_text">
                {{
                  content.longdescription
                    ? content.longdescription.length > 180
                      ? content.longdescription.slice(0, 180) + "..."
                      : content.longdescription
                    : content.shortdescription
                }}
                <span
                  class="readMore"
                  @click="readMore = true"
                  v-if="(getStringLength && getStringLength > 180) || content.longdescription"
                  >{{ $t("read more") }}</span
                >
              </p>
              <div class="content_detail_description_grid_icon_list">
                <div class="activity-btns">
                  <LikeBtn :content="content" :width="'22px'" :height="'22px'" class="hover-change"></LikeBtn>
                  <div class="triangle" :class="triangleColor"></div>
                  <div class="tooltip" :class="toolTipColor">
                    <span>{{ $t("like") }}</span>
                  </div>
                  <!-- <p class="activity-counts">{{likeCountDisplay}}</p> -->
                </div>

                <div class="activity-btns">
                  <DislikeBtn :content="content" :width="'22px'" :height="'22px'" class="hover-change"></DislikeBtn>
                  <div class="triangle" :class="triangleColor"></div>
                  <div class="tooltip" :class="toolTipColor">
                    <span>{{ $t("dislike") }}</span>
                  </div>
                  <!-- <p class="activity-counts">{{dislikeCountDisplay}}</p> -->
                </div>

                <div class="activity-btns">
                  <WatchListBtn :content="content" :width="'22px'" :height="'22px'" class="hover-change"></WatchListBtn>
                  <div class="triangle" :class="triangleColor"></div>
                  <div class="tooltip" :class="toolTipColor">
                    <span>{{ $t("My List") }}</span>
                  </div>
                </div>

                <div class="activity-btns">
                  <detailShare :content="content" :width="'22px'" :height="'22px'" class="hover-change"></detailShare>
                  <div class="triangle" :class="triangleColor"></div>
                  <div class="tooltip" :class="toolTipColor">
                    <span>{{ $t("share") }}</span>
                  </div>
                </div>
              </div>
              <div class="content_detail_description_grid_btns">
                <button
                  v-if="isButtonEnable && contentTags !== 'upcoming'"
                  class="button-primary login"
                  @click="buttonHandler"
                >
                  <span class="btn-txt-color">{{ $t(buttonStatus) }}</span>
                </button>
                <button
                  class="button-secondary"
                  @click="watchTrailer()"
                  v-if="content.trailer && content.trailer.length > 0"
                >
                  {{ $t("WATCH TRAILER") }}
                </button>
              </div>
            </div>
            <!-- <div class="content_detail_description_grid_cast_crew" v-if="content.details">
              <p class="content_detail_description_grid_cast_crew_cast">
                <span>
                  Cast:
                  <span v-for="(item, index) in castData" :key="index">{{ item }}</span>
                </span>
              </p>
              <p class="content_detail_description_grid_cast_crew_crew">
                <span>
                  Crew:
                  <span v-for="(item, index) in crewData" :key="index">{{ item }}</span>
                </span>
              </p>
            </div> -->
          </div>
          <div v-else class="content_detail_description_read_more">
            <img src="@/assets/icons/ClosePopup.svg" alt="close" @click="readMore = false" />
            <p>{{ content.longdescription ? content.longdescription : content.shortdescription }}</p>
          </div>
        </div>
        <div class="content_detail_linear_gradient"></div>
      </div>
      <div class="content_detail_tabs">
        <div>
          <ul class="content_detail_tabs_list">
            <li
              v-for="(item, index) in tabList"
              :key="index"
              @click="selectTab(index)"
              :class="selectedSection === index ? activeTab : ''"
            >
              {{ $t(item) }}
            </li>
          </ul>
        </div>
      </div>
      <div class="content_detail_tab_component" v-if="tabList.length > 0">
        <keep-alive v-for="(item, index) in tabList" :key="index">
          <component
            v-if="index == selectedSection"
            :is="item"
            :content="content"
            :playerInstance="playerInstance"
            :changeStyles="true"
          ></component>
        </keep-alive>
      </div>
    </div>
  </Popup>
</template>

<script>
import { vLivePlaylistItem } from "/src/vue-player/vLiveWebPlayer.js";
import Utility from "@/mixins/Utility.js";
import { eventBus } from "@/eventBus";
import { mapGetters, mapMutations } from "vuex";
import { _projectName } from "@/provider-config.js";
import { store } from "@/store/store";

export default {
  props: {
    closePopup: {
      type: Function,
    },
    content: {
      type: Object,
    },
    playerInstance: {
      type: Object,
    },
  },
  data() {
    return {
      width: "60%",
      margin: "5% auto",
      radiusType: "full",
      placeholder: false,
      castData: [],
      crewData: [],
      tabList: [],
      activeTab: "active-tab",
      // tabComponent: null,
      selectedSection: null,
      readMore: false,
      subscriptions: [],
      availabilities: [],
      purchases: [],
      filteredAvailabilities: [],
      pricemodel: [],
      buttonStatus: null,
      isButtonEnable: false,
      buttonLoader: true,
      localDisplayLang: null,
      showDarkClose: true,
      triangleColor: null,
      toolTipColor: null,
      fetchStreamUrlTask: null,
      playbackInitiated: false,
      isMpegRequired: false,
    };
  },
  computed: {
    ...mapGetters(["subscriberid", "availabilityList", "subscriptionList", "purchaseList", "appConfig"]),
    contentTags() {
      return this.content.tags[0].toLowerCase();
    },
    getStringLength() {
      if (this.content.longdescription || this.content.shortdescription) {
        return this.content.longdescription
          ? this.content.longdescription.length
          : this.content.shortdescription.length;
      }

      return null;
    },
  },
  watch: {
    content(val) {
      if (val) {
        this.selectedSection = null;
        this.tabList = [];
        this.fetchTabList();
        this.selectTab(0);
      }
    },

    availabilityList(val) {
      if (Array.isArray(val) && val.length > 0) {
        this.availabilities = val;
        this.getAvailability(this.content);
      }
    },
    subscriptionList(val) {
      if (Array.isArray(val) && val.length > 0) {
        this.subscriptions = val;
        this.getAvailability(this.content);
      }
    },
  },
  created() {
    this.isMpegRequired = this.checkMpegRequired();
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    //assigning availability list response.
    if (this.availabilityList !== undefined) {
      this.availabilities = this.availabilityList;
    }

    //assigning subscription list response.
    if (this.subscriptionList !== undefined) {
      this.subscriptions = this.subscriptionList;
    }

    //assinging purchase list response.
    if (this.purchaseList !== undefined) {
      this.purchases = this.purchaseList;
    }

    if (this.content.category !== "TVSHOW") {
      // this.fetchButtonStatus();
      this.getAvailability(this.content);
    }

    this.fetchTabList();
    this.selectTab(0);

    // read More Color
    this.readMoreColor =
      _projectName === "NET5" || _projectName === "VLIVE" || _projectName === "Noor Play"
        ? "readMore-color"
        : _projectName === "NAMMAFLIX"
        ? "readMore-color-white"
        : "";

    // active tab color
    this.activeTab =
      _projectName === "NET5" || _projectName === "VLIVE" || _projectName === "Noor Play"
        ? "active-tab"
        : _projectName === "NAMMAFLIX"
        ? "active-tab-white"
        : "";

    // tooltip color
    this.toolTipColor =
      _projectName === "NET5" || _projectName === "VLIVE" || _projectName === "Noor Play"
        ? "tooltip-color"
        : _projectName === "NAMMAFLIX"
        ? "tooltip-color-white"
        : "";

    // tooltip triangle Color change
    this.triangleColor =
      _projectName === "NET5" || _projectName === "VLIVE" || _projectName === "Noor Play"
        ? "triangle-color"
        : _projectName === "NAMMAFLIX"
        ? "triangle-color-white"
        : "";
  },
  mounted() {
    if (screen.width < 576) {
      this.margin = "0";
      this.width = "100%";
    }

    eventBus.$on("replace-content", (content) => {
      console.log("Replace content : ", content);
      this.selectedSection = null;
      this.tabList = [];
      this.fetchTabList();
      this.content = content;
      this.selectTab(0);
    });

    eventBus.$on("close-detail-popup", () => {
      console.log("This event is triggered");
      this.closePopup();
    });

    eventBus.$on("player-visibility", (isVisible) => {
      if (isVisible == false && this.playbackInitiated == true) {
        this.playbackInitiated = false;
        this.playerInstance.deregisterEvent("currentcontentended");
        // this.playerInstance.deregisterEvent('loadchosencontent');
      }
    });
  },
  methods: {
    ...mapMutations(["setRegActiveDeeplink"]),
    checkMpegRequired() {
      let isMpegRequired = false;
      let videoTag = document.createElement("video");
      let platformOs = navigator.platform;

      if (videoTag.canPlayType("application/vnd.apple.mpegurl")) {
        if (platformOs.startsWith("Mac")) {
          isMpegRequired = true;
        }
      }

      return isMpegRequired;
    },
    isObjectTag(content) {
      if (Array.isArray(content.objecttag)) {
        return content.objecttag.length === 0 ? false : true;
      } else {
        return content.objecttag === null ? false : true;
      }
    },

    fetchTabList() {
      if (this.content.category === "MOVIE" && this.content.trailer && this.content.trailer.length > 0) {
        this.tabList = ["Trailer", "Related"];
      } else if (this.content.category === "MOVIE") {
        this.tabList = ["Related"];
      } else if (
        this.content.category === "TVSHOW" &&
        this.content.trailer &&
        this.content.trailer.length > 0 &&
        this.content.tags[0].toLowerCase() != "upcoming"
      ) {
        console.log("coming to tv show 1");
        this.tabList = ["Episodes", "Trailer", "Related"];
      } else if (this.content.category === "TVSHOW" && this.content.trailer && this.content.trailer.length > 0) {
        console.log("coming to tv show 2");
        this.tabList = ["Episodes", "Trailer", "Related"];
        if (this.content.tags[0].toLowerCase() === "upcoming") {
          console.log("updated tab list----------", this.tabList);
          this.tabList.splice(0, 1);
        }
      } else if (this.content.category === "TVSHOW" && !this.content.trailer) {
        this.tabList = ["Episodes", "Related"];
      }
    },

    // fetchTabList() {
    //   if (this.content.category === "MOVIE" && this.content.trailer && this.content.trailer.length > 0) {
    //     this.tabList = ["Trailer", "Related"];
    //   } else if (this.content.category === "MOVIE") {
    //     this.tabList = ["Related"];
    //   } else if (this.content.category === "TVSHOW" && this.content.trailer && this.content.trailer.length > 0) {
    //     this.tabList = ["Episodes", "Trailer", "Related"];
    //   } else if (this.content.category === "TVSHOW") {
    //     this.tabList = ["Episodes", "Related"];
    //     if (this.contentTags === "upcoming") {
    //       this.tabList.splice(0, 1);
    //     }
    //   }
    // },
    selectTab(index) {
      this.selectedSection = index;
      // this.tabComponent = this.tabList[index];
    },
    buildPlayerPlaylist(episodeList, isTrailer, startIndex) {
      let playlist = [];
      for (var aIndex = startIndex; aIndex < episodeList.length; aIndex++) {
        let playlistItem = this.createPlayerPlaylistItem(episodeList[aIndex], isTrailer, 0);
        playlist.push(playlistItem);
      }

      return playlist;
    },
    watchTrailer() {
      //To aviod drm check.
      console.log("Watch trailer");
      this.content.trailerUrl = this.content.trailer[0].filelist[0].filename;

      this.showPlayer();
      let contentList = [this.content];
      this.loadContentMetaData(contentList[0], null);
      let playlist = this.buildPlayerPlaylist(contentList, true, 0);
      this.playerInstance.loadPlaylist(playlist, contentList.length);

      this.playerInstance.loadContent(0).then(() => {
        this.playerInstance.play().then(() => {
          console.log("Playback successful");
        });
      });
    },
    togglePlayer(state) {
      let payload = {
        content: this.content,
        state: state,
      };
      eventBus.$emit("togglePlayer", payload);
    },
    buttonHandler() {
      if (!this.subscriberid && (this.buttonStatus === "SUBSCRIBE & WATCH" || this.buttonStatus === "LOGIN & WATCH")) {
        //open lookup popup.
        let payload = {
          state: true,
          formType: "lookup",
        };
        eventBus.$emit("authPopup", payload);
      } else if (this.subscriberid && this.buttonStatus === "SUBSCRIBE & WATCH") {
        console.log("THIS IS THE SUBSCRIBE POPUP");
        this.setRegActiveDeeplink(true);
        //show subscribe popup.
        let payload = { listType: "onlySubscribe" };
        eventBus.$emit("subscribePopup", payload);
      } else if (this.subscriberid && this.buttonStatus === "PLAY NOW") {
        this.showPlayer();

        let contentList = [this.content];
        console.log("Content list : ", contentList);
        this.loadChosenIndexContent(0, contentList, contentList.length, true);

        // this.closePopup();
      }
    },
    loadChosenIndexContent(episodeIndex, episodeList, totalCount, isFirstTime = true) {
      this.fetchContentStreamUrl(episodeList[episodeIndex], this.isMpegRequired)
        .then((resp) => {
          let loadPlayer = false;

          if (typeof resp == "object" && !resp.data.errorcode) {
            this.handleFetchContentStreamUrl(resp, episodeList[episodeIndex]);
            loadPlayer = true;
          } else if (typeof resp == "boolean" && resp == true) {
            loadPlayer = true;
          }
          if (loadPlayer == true) {
            this.loadContentMetaData(episodeList[episodeIndex], null);

            let playlist = this.buildPlayerPlaylist(episodeList, false, 0);
            this.playerInstance.loadPlaylist(playlist, totalCount);

            // if (typeof resp == 'object') {
            //     if(isFirstTime == true) {
            //
            //     } else {
            //         let playlist = this.playerInstance.playlist;
            //         let updatedContentItem = this.createPlayerPlaylistItem(episodeList[episodeIndex], false, 0);
            //         playlist.updateItem(episodeIndex, updatedContentItem)
            //     }
            // }

            this.playerInstance.loadContent(episodeIndex).then(() => {
              this.playerInstance.play().then(() => {
                console.log("Playback successful");
              });
            });
          } else {
            let playbackError = true;

            if (typeof resp == "object") {
              playbackError = resp.data;
            }
            throw playbackError;
          }
        })
        .catch((error) => {
          console.log("Content playback failed : ", error);
          this.hidePlayer();
        });
    },
    getAvailability(contentItem) {
      if (contentItem.hasOwnProperty("contentdetails") && contentItem.contentdetails.length > 0) {
        this.isButtonEnable = true;
        let packageDetails = this.getFilteredPackage(contentItem);
        let availPlanList = this.getAvailabilityAndPlanDetails(packageDetails, this.availabilities);
        let pricemodel = availPlanList.pricemodel[0];
        //based on pricemodel of availabilities assigning buttonstatus.
        if (pricemodel.includes("PLAN")) {
          this.buttonStatus = "SUBSCRIBE & WATCH";
        } else if (pricemodel.includes("FREE")) {
          this.buttonStatus = "LOGIN & WATCH";
        }

        let filteredAvailabilityList = availPlanList.filteredAvailabilities;

        console.log("filtered availabilities", filteredAvailabilityList);

        filteredAvailabilityList.every((availability) => {
          if (availability.pricemodel === "PLAN") {
            if (this.subscriberid && this.subscriptions.length > 0) {
              this.subscriptions.every((subscription) => {
                // Check if next billing date is greater than today's date.
                if (this.getDateForPlayback(subscription.nextbilling) < this.getTodaysDate()) return;

                let value = subscription.availabilityset.includes(availability.availabilityid);

                if (value) {
                  contentItem.isDrmContent = true;
                  this.isButtonEnable = true;
                  this.buttonStatus = "PLAY NOW";
                  contentItem.videoType = "Content";
                  contentItem.playback_details = {
                    packageid: packageDetails.packageid,
                    availabilityid: availability.availabilityid,
                    drmscheme: packageDetails.drmscheme[0],
                  };

                  return false;
                }
              });
            } else if (this.subscriberid && this.subscriptions.length === 0) {
              console.log(
                "content price model is not free and user has to subscribe to the plan to watch this content"
              );
            }
          } else if (availability.pricemodel == "FREE") {
            contentItem.isDrmContent = true;
            this.isButtonEnable = true;
            this.buttonStatus = "PLAY NOW";
            contentItem.videoType = "Content";
            //creating this playback_details for content playback.
            contentItem.playback_details = {
              packageid: packageDetails.packageid,
              availabilityid: availability.availabilityid,
              drmscheme: packageDetails.drmscheme[0],
            };
            return false;
          }
        });
      }
    },
    getAvailabilityAndPlanDetails(packageDetails, availabilitiesList) {
      let priceAndAvailabilityData = {};
      priceAndAvailabilityData.pricemodel = [];
      priceAndAvailabilityData.filteredAvailabilities = [];

      packageDetails.availabilityset.forEach((availability) => {
        let availabilityIndex = availabilitiesList.findIndex((element) => {
          return element.availabilityid === availability;
        });

        if (availabilityIndex > -1) {
          priceAndAvailabilityData.pricemodel.push(availabilitiesList[availabilityIndex].pricemodel);
          priceAndAvailabilityData.filteredAvailabilities.push(availabilitiesList[availabilityIndex]);
        }
      });

      return priceAndAvailabilityData;
    },
    getFilteredPackage(contentItem) {
      let temporaryVideoTag = document.createElement("video");
      let hasNativeHlsSupport = temporaryVideoTag.canPlayType("application/vnd.apple.mpegurl");

      let isAndroid = navigator.userAgent.includes("Android");

      let packageList = [];

      //pick content details if that availability id is there in availability list.
      this.availabilities.forEach((availability) => {
        contentItem.contentdetails.forEach((element) => {
          if (element.availabilityset.includes(availability.availabilityid)) {
            packageList.push(element);
          }
        });
      });

      let packageIndex = packageList.findIndex((contentPackage) => {
        if (!isAndroid && hasNativeHlsSupport != "") {
          return contentPackage.drmscheme[0] === "FAIRPLAY";
        } else {
          return contentPackage.drmscheme[0] === "WIDEVINE";
        }
      });

      if (packageIndex == -1) {
        return packageList[
          packageList.findIndex((contentPackage) => {
            return packageList.drmscheme[0] === "NONE";
          })
        ];
      } else if (packageIndex > -1) {
        return packageList[packageIndex];
      }
    },
    buildContentStreamRequest(contentItem, isMpegRequired) {
      let payload = {
        contentid: contentItem.objectid,
        params: {
          availabilityid: contentItem.playback_details.availabilityid,
          packageid: contentItem.playback_details.packageid,
        },
      };

      if (isMpegRequired == true) {
        payload.params.mpegtspackage = "YES";
      }

      return payload;
    },

    fetchContentStreamUrl(contentItem, isMpegRequired) {
      return new Promise((resolve, reject) => {
        console.log("Fetch content stream : ", contentItem.streamUrl);
        if (contentItem.streamUrl != undefined && contentItem.streamUrl != "") {
          console.log("Fetch stream resolved");
          resolve(true);
        } else {
          console.log("Fetching stream");
          resolve(store.dispatch("contentStreamUrl", this.buildContentStreamRequest(contentItem, isMpegRequired)));
        }
      });
    },
    handleFetchContentStreamUrl(response, contentItem) {
      contentItem.streamUrl = response.data.streamfilename;
			contentItem.scrubbing = response.data.packagedfilelist && response.data.packagedfilelist.scrubbing ? response.data.packagedfilelist.scrubbing : '';
    },
    extractThumbnailPoster(contentItem) {
      console.log("content item", contentItem);
      let posterKey = contentItem.poster;
      let thumbNailUrl = null;

      if (posterKey && Array.isArray(posterKey) == true && posterKey.length > 0) {
        // let posterFileList = posterKey[0].filelist;

        let posterFileList = posterKey.find((posterKeyNode) => posterKeyNode.postertype == "LANDSCAPE").filelist;

        if (posterFileList && Array.isArray(posterFileList) == true && posterFileList.length > 0) {
          let thumbNailNode = posterFileList.find((posterNode) => posterNode.quality == "THUMBNAIL");

          if (thumbNailNode) {
            thumbNailUrl = thumbNailNode.filename;
          }
        }
      }

      return thumbNailUrl;
    },
    createPlayerPlaylistItem(content, isTrailer, contentHead) {
      let playlistItem = new vLivePlaylistItem({
        contentId: content.objectid != undefined && content.objectid != "" ? content.objectid : "",
        contenttype: content.category,
        title: content.title,
        description: content.shortdescription,
        thumbnail: this.extractThumbnailPoster(content),
        source: isTrailer
          ? [content.trailerUrl]
          : content.streamUrl != undefined && content.streamUrl != ""
          ? [content.streamUrl]
          : [],
        licenseServer: this.appConfig.providerDetails.drmLicenseUrl,
        packageid: content.playback_details ? content.playback_details.packageid : "",
        providerid: "noorplay",
        drmscheme: content.playback_details ? content.playback_details.drmscheme : "",
        availabilityid: content.playback_details ? content.playback_details.availabilityid : "",
        providerSession: localStorage.getItem("sessionToken"),
        isDrmContent: isTrailer ? false : content.isDrmContent,
        skip: content.skip && Array.isArray(content.skip) && content.skip.length > 0 ? content.skip : "",
        position: contentHead,
				scrubbing: content.scrubbing ?content.scrubbing[0] : '',
      });

      return playlistItem;
    },
    loadContentMetaData(contentMetaData, seriesMetaData) {
      let metaData = {
        content: contentMetaData,
        seriesContent: seriesMetaData,
      };

      eventBus.$emit("load-player-content", metaData);
    },
    loadNextContentData(endedContentIndex) {
      this.hidePlayer();
    },
    showPlayer() {
      this.playerInstance.blowUpPlayer();
      this.playerInstance.registerEvent("currentcontentended", this.loadNextContentData);
      // this.playerInstance.registerEvent("loadchosencontent", this.loadChosenContentData);
      this.playbackInitiated = true;
      eventBus.$emit("player-visibility", true);
    },
    hidePlayer() {
      this.playerInstance.shrinkPlayer();

      eventBus.$emit("player-visibility", false);

      let payload = { content: null, seriesContent: null };
      eventBus.$emit("load-player-content", payload);

      this.playerInstance.unloadPlayer(true);
    },
    getPoster() {
      let content = this.content;
      if (content && content.poster && content.poster.length && !content.inwatchlist) {
        let index = content.poster.findIndex((element) => {
          return element.postertype === "LANDSCAPE";
        });

        if (index > -1) {
          return content.poster[index].filelist[1].filename;
        }
      } else if (content.inwatchlist) {
        return content.poster.landscape;
      }
    },
  },
  components: {
    Popup: () => import(/* webpackChunkName: "Popup" */ "@/components/Slots/Popup.vue"),
    LikeBtn: () => import(/* webpackChunkName: "likebutton" */ "@/components/SvgImages/LikeBtn.vue"),
    DislikeBtn: () => import(/* webpackChunkName: "dislikebutton" */ "@/components/SvgImages/DislikeBtn.vue"),
    WatchListBtn: () => import(/* webpackChunkName: "watchlistbtn" */ "@/components/watchlist/watchListBtn.vue"),
    detailShare: () => import(/* webpackChunkName: "detailshare" */ "@/components/Templates/detailShare.vue"),
    Related: () => import(/* webpackChunkName: "related" */ "@/components/Templates/relatedCarousel.vue"),
    Trailer: () => import(/* webpackChunkName: "trailers" */ "@/components/MobileTemplates/trailerListing.vue"),
    Episodes: () => import(/* webpackChunkName: "episodes" */ "@/components/Templates/episodesCarousel.vue"),
  },
  beforeDestroy() {
    eventBus.$off("close-detail-popup");
    eventBus.$off("replace-content");
  },
  mixins: [Utility],
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
.content_detail {
  position: relative;
  // padding-bottom: 5rem;
  &_container {
    height: 65vh;
    width: 100%;
    &_card_image {
      position: relative;
      border-top-left-radius: 0.6rem;
      border-top-right-radius: 0.6rem;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 100%;
      &_content_tag_container {
        padding-top: 10px;
        .tag_left {
          margin-bottom: 20px;
          padding: 5px 8px;
          background-color: $btn-clr;
          font-family: $font-helvetica-medium;
          color: #ffffff;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          width: fit-content;
          p {
            font-size: 0.7rem;
          }
        }
        .tag_right {
          margin-bottom: 20px;
          padding: 5px 8px;
          background-color: $btn-clr;
          font-family: $font-helvetica-medium;
          color: #ffffff;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          width: fit-content;
          p {
            font-size: 0.7rem;
          }
        }
      }
      &_info {
        position: absolute;
        bottom: 0;
        padding: 0.6rem 2rem;
        z-index: 1001;
        &_content_tag {
          margin-bottom: 20px;
          padding: 5px 8px;
          background-color: $btn-clr;
          font-family: $font-helvetica-medium;
          color: #ffffff;
          border-radius: 5px;
          width: fit-content;
          p {
            font-size: 0.7rem;
          }
        }
        &_title {
          font-family: $font-helvetica-medium;
          color: #ffffff;
          font-size: 2rem;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 1.25rem;
        }
        &_tags {
          display: flex;
          margin-top: 20px;
          li {
            font-family: $font-helvetica-medium;
            color: #efeff4;
            font-size: 0.9rem;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 1.25rem;
            text-transform: uppercase;
            &:nth-child(even) {
              margin: 0 20px;
            }
          }
        }
      }
    }
  }
  &_linear_gradient {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(to bottom, rgba(26, 26, 26, 0) 0%, #1a1a1a 68%);
    border-bottom-left-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
  }
  &_description {
    // background-color: rgba(10.2, 10.2, 10.2, 1);
    padding: 0.6rem 2rem;
    position: relative;
    z-index: 1001;
    font-family: $font-helvetica-medium;
    color: #ffffff;
    font-size: 0.6rem;
    &_duration {
      opacity: 0.5;
      color: #ffffff;
      font-family: $font-helvetica-medium;
      font-size: 0.9rem;
      letter-spacing: 0;
      line-height: 1.25rem;
      font-weight: 500;
      &_movie {
        display: flex;
        // img {
        //   margin-right: 5px;
        // }
        .flex {
          display: flex;
        }
        .margin-left {
          margin-left: 5px;
        }
        .margin-right {
          margin-right: 5px;
        }
      }
      &_tvshow {
        display: flex;
        img {
          margin-right: 5px;
        }
        p {
          margin: 0 5px;
        }
      }
    }
    &_grid {
      margin-top: 10px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 10px;
      justify-content: space-between;
      text-align: justify;
      &_text {
        opacity: 0.5;
        color: #ffffff;
        font-family: $font-helvetica-medium;
        font-size: 0.9rem;
        letter-spacing: 0;
        line-height: 1.5rem;
        font-weight: 400;
        // padding-right: 2rem;
        span {
          color: $btn-clr;
          font-family: $font-helvetica-medium;
          font-size: 0.6rem;
          letter-spacing: 0;
          line-height: 1.5rem;
          font-weight: 400;
          cursor: pointer;
        }
        .readMore {
          line-height: 0;
        }
      }
      &_icon_list {
        margin: 15px 0;
        display: flex;
        .activity-btns {
          position: relative;
          margin-right: 15px;
          cursor: pointer;
          .activity-counts {
            color: #efeff4;
            font-family: $font-regular;
            text-align: center;
            font-size: 0.9em;
          }
          .triangle {
            display: none;
            position: absolute;
            top: -4px;
            left: 10px;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
          }
          .triangle-color {
            border-top: 5px solid #560C1D;
          }
          .triangle-color-white {
            border-top: 5px solid rgba(255, 255, 255, 0.6);
          }
          .tooltip {
            display: none;
            position: absolute;
            top: -32px;
            left: -12px;
            width: max-content;
            padding: 5px 10px;
            border-radius: 5px 10px;
            background: rgba(0, 0, 0, 1);
            span {
              font-size: 0.8rem;
              font-family: $font-regular;
              opacity: 0.8;
            }
          }
          .tooltip-color {
            border: 1px solid $btn-clr;
            span {
              color: $btn-clr;
            }
          }
          .tooltip-color-white {
            border: 1px solid rgba(255, 255, 255, 0.6);
            span {
              color: $clr-light-gd2;
            }
          }
          &:focus {
            outline: none;
          }
          &:hover {
            .triangle {
              display: block;
            }
            .tooltip {
              display: block;
            }
            margin-top: -3px;
            transition: 0.3s;
          }
          .hover-change {
            // opacity: 0.7;
            &:hover {
              opacity: 1;
            }
          }
        }
      }
      &_btns {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 10px;
      }
      &_cast_crew {
        p {
          margin-bottom: 5px;
        }
        // &_cast {
        // }
        // &_crew {
        // }
      }
    }
    &_read_more {
      margin-top: 10px;
      border: 1px solid rgba(239, 239, 244, 0.5);
      border-radius: 10px;
      padding: 15px;
      padding-right: 80px;
      position: relative;
      img {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 15px;
        cursor: pointer;
        opacity: 0.8;
        &:hover {
          opacity: 1;
        }
      }
      p {
        opacity: 0.6;
        color: #ffffff;
        font-family: $font-regular;
        font-size: 0.9rem;
        letter-spacing: 0;
        line-height: 1.5rem;
        text-align: justify;
      }
    }
  }
  &_tabs {
    padding-bottom: 15px;
    &_list {
      position: relative;
      display: flex;
      margin: 0 2rem;
      padding-top: 0.6rem;
      border-bottom: 1px solid #32363e;
      z-index: 999;
      li {
        opacity: 0.5;
        color: #efeff4;
        font-family: $font-helvetica-medium;
        position: relative;
        z-index: 999;
        cursor: pointer;
        &:nth-child(even) {
          margin: 0 20px;
        }
      }
    }
  }
  &_tab_component {
    padding: 0 2rem;
    padding-bottom: 1rem;
    border-bottom-left-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
  }
}

.active-tab {
  border-bottom: 3px solid $btn-clr;
  opacity: 1 !important;
  padding-bottom: 5px;
}

@media only screen and (max-width: 576px) {
  .login {
    padding: 0.4rem;
    margin-top: 0.2rem;
  }
  .content_detail {
    height: 100vh;
    // overflow: hidden;
    &_container {
      height: 175px;
      &_card_image {
        border-radius: 0%;
        &_info {
          padding: 0.6rem 1rem;
          &_title {
            font-size: 1.25rem;
            line-height: 15px;
          }
          &_tags {
            margin-top: 10px;
            li {
              font-size: 0.6rem;
            }
          }
        }
      }
    }
    &_linear_gradient {
      border-radius: 0%;
    }
    &_description {
      padding: 0rem 1rem;
      &_duration {
        font-size: 0.6rem;
      }
      &_grid {
        grid-template-columns: repeat(1, 1fr);
        &_text {
          font-size: 0.6rem;
          line-height: 15px;
          span {
            font-size: 0.3rem;
          }
          .readMore {
            font-size: 0.6rem;
            line-height: 0;
          }
        }
        &_icon_list {
          margin: 10px 0;
        }
      }
      &_read_more {
        padding-right: 0px;
        padding: 25px 15px;
        img {
          top: 10px;
          right: 10px;
        }
        p {
          font-size: 0.6rem;
          line-height: 1.2rem;
        }
      }
    }
    &_tabs {
      padding-bottom: 5px;
      &_list {
        margin: 0 1rem;
        li {
          font-size: 0.7rem;
        }
      }
    }
    &_tab_component {
      padding: 0 1rem;
    }
  }
}

@media only screen and (min-width: 577px) and (max-width: 767px) {
  .login {
    padding: 0.4rem;
    margin-top: 0.2rem;
  }
  .content_detail {
    height: 100%;
    // overflow: hidden;
    &_container {
      height: 225px;
      &_card_image {
        border-radius: 0%;
        &_info {
          padding: 0.6rem 1rem;
          &_title {
            font-size: 1.25rem;
            line-height: 15px;
          }
          &_tags {
            margin-top: 10px;
            li {
              font-size: 0.6rem;
            }
          }
        }
      }
    }
    &_linear_gradient {
      border-radius: 0%;
    }
    &_description {
      padding: 0rem 1rem;
      &_duration {
        font-size: 0.6rem;
      }
      &_grid {
        grid-template-columns: repeat(1, 1fr);
        &_text {
          font-size: 0.6rem;
          line-height: 15px;
          span {
            font-size: 0.3rem;
          }
          .readMore {
            font-size: 0.6rem;
            line-height: 0;
          }
        }
        &_icon_list {
          margin: 10px 0;
        }
      }
      &_read_more {
        padding-right: 0px;
        padding: 25px 15px;
        img {
          top: 10px;
          right: 10px;
        }
        p {
          font-size: 0.6rem;
          line-height: 1.2rem;
        }
      }
    }
    &_tabs {
      padding-bottom: 5px;
      &_list {
        margin: 0 1rem;
        li {
          font-size: 0.7rem;
        }
      }
    }
    &_tab_component {
      padding: 0 1rem;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 900px) {
  .login {
    padding: 0.4rem;
    margin-top: 0.2rem;
  }
  .content_detail {
    height: 100%;
    // overflow: hidden;
    &_container {
      height: 275px;
      &_card_image {
        border-radius: 0%;
        &_info {
          padding: 0.6rem 1rem;
          &_title {
            font-size: 1.25rem;
            line-height: 15px;
          }
          &_tags {
            margin-top: 10px;
            li {
              font-size: 0.6rem;
            }
          }
        }
      }
    }
    &_linear_gradient {
      border-radius: 0%;
    }
    &_description {
      padding: 0rem 1rem;
      &_duration {
        font-size: 0.6rem;
      }
      &_grid {
        grid-template-columns: repeat(1, 1fr);
        &_text {
          font-size: 0.6rem;
          line-height: 15px;
          span {
            font-size: 0.3rem;
          }
          .readMore {
            font-size: 0.6rem;
            line-height: 0;
          }
        }
        &_icon_list {
          margin: 10px 0;
        }
      }
      &_read_more {
        padding-right: 0px;
        padding: 25px 15px;
        img {
          top: 10px;
          right: 10px;
        }
        p {
          font-size: 0.6rem;
          line-height: 1.2rem;
        }
      }
    }
    &_tabs {
      padding-bottom: 5px;
      &_list {
        margin: 0 1rem;
        li {
          font-size: 0.7rem;
        }
      }
    }
    &_tab_component {
      padding: 0 1rem;
    }
  }
}
</style>
